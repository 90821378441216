<template>
  <v-card
    elevation="0"
    class="pa-5"
    height="100vh"
    style="text-align:center;"
  >
    <v-card-title class="pb-0">
      {{ $t('courierSet.upsAuthorization.requiredTokenDataMessage') }}
    </v-card-title>
    <v-row class="pt-5">
      <v-col
        cols="6"
        class="pb-0"
      >
        <text-field
          v-model="apiClientId"
          :title="$t('courierSet.clientId')"
          rules="required"
        />
      </v-col>
      <v-col
        cols="6"
        class="pb-0"
      >
        <text-field
          v-model="apiClientSecret"
          :title="$t('courierSet.clientSecret')"
          type="password"
          rules="required"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-btn
          color="primary lighten-1"
          elevation="0"
          width="100%"
          @click="onGenerateToken"
        >
          {{ $t('courierSet.upsAuthorization.generateToken') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';

export default {
  components: {
    TextField,
  },
  data: () => ({
    apiClientId: '',
    apiClientSecret: '',
  }),
  methods: {
    async onGenerateToken() {
      const { code } = this.$route.query;
      const payload = {
        grant_type: 'authorization_code',
        code,
        redirect_uri: 'http://localhost:8081/upsAuthorization/',
      };

      const resp = await fetch(
        'https://wwwcie.ups.com/security/v1/oauth/token',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${btoa(`${this.apiClientId}:${this.apiClientSecret}`)}`,
          },
          body: new URLSearchParams(payload).toString(),
        },
      );

      const data = await resp.text();
      console.log(data);
    },
  },
};
</script>
